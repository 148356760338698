<template>
  <div class="row">
    <div class="col-12">
      <h1 class="text-blue">Administrar mis ubicaciones</h1>
      <div class="d-flex justify-content-between my-5">
        <div class="d-flex align-items-center">
          <i class="bi bi-search"></i>
          <input
            type="text"
            class="mx-2 form-control border-0"
            placeholder="Ingresa el nombre de la ubicación"
            style="min-width: 310px"
          />
        </div>
        <div class="d-flex">
          <router-link
            to="../equipos/ubicacion/crear"
            class="btn bg-white d-flex align-items-center"
          >
            <i
              class="bi bi-plus-lg text-white p-2 fw-bold bg-blue rounded-circle"
            ></i>
            <p class="mx-3">Agregar ubicación</p>
          </router-link>
        </div>
      </div>
      <div class="d-flex">
        <select
          class="form-select me-5 border-0"
          aria-label="Default select example"
          v-model="departamentoId"
        >
          <option disabled value="">Seleccione departamento</option>
          <option
            v-for="(departamento, t) in departamentos"
            :key="t"
            :value="departamento.id"
          >
            {{ departamento.name }}
          </option>
        </select>
        <select
          class="form-select border-0"
          aria-label="Default select example"
          v-model="ciudadId"
        >
          <option disabled value="">Seleccione ciudad</option>
          <option v-for="(ciudad, k) in ciudades" :key="k" :value="ciudad.id">
            {{ ciudad.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div
        class="my-5 overflow-auto"
        style="max-height: 600px; background-color: transparent"
      >
        <div
          class="card px-5 py-2 my-4 shadow-none"
          v-for="(ubicacion, i) in ubicaciones"
          :key="i"
        >
          <div class="d-flex justify-content-end w-100">
            <button
              class="btn p-0 mx-2"
              data-bs-toggle="modal"
              data-bs-target="#modalEliminarUbicacion"
            >
              <i class="bi bi-trash-fill text-blue"></i>
            </button>
            <div
              class="modal fade"
              id="modalEliminarUbicacion"
              tabindex="-1"
              aria-labelledby="modalEliminarUbicacionLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered"
                style="border-radius: 38px; max-height: 321px; max-width: 375px"
              >
                <div class="modal-content p-5">
                  <div class="text-center my-5">
                    <h2 class="text-blue">¿Eliminar?</h2>
                  </div>
                  <div class="text-gray text-center">
                    <p>¿¡Está seguro de querer eliminar esta ubicación!?</p>
                  </div>
                  <div class="text-center text-gray my-5">
                    <p>
                      Debe tener en cuenta que al eliminar la ubicación todas
                      las áreas y equipos que se encuentran en esta serán
                      eliminados, si desea conservarlos debe cambiarlos de
                      ubicación.
                    </p>
                  </div>
                  <div class="text-center my-5">
                    <button
                      @click="borrarUbicacion(ubicacion._id)"
                      class="btn bg-blue rounded-pill text-white"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    >
                      Confirmar
                    </button>
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      class="btn text-blue rounded-pill"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <router-link
              :to="{
                name: 'editar-sedes',
                params: { id: ubicacion._id },
              }"
              class="bi bi-pencil-fill text-blue"
            ></router-link>
          </div>
          <div class="row border-bottom pb-5">
            <div class="d-flex">
              <div class="d-flex align-items-center">
                <i class="bi bi-three-dots-vertical"></i>
                <i class="bi bi-three-dots-vertical"></i>
              </div>
              <div>
                <i
                  class="bi bi-geo-alt-fill fs-1 px-3 py-2 mx-3 bg-blue rounded-circle text-white"
                ></i>
              </div>
              <div class="d-block w-75">
                <h4 class="text-blue fw-bold">{{ ubicacion.nombre }}</h4>
                <div class="d-flex justify-content-between w-100">
                  <div>
                    <div class="fw-bold">
                      País:
                      <span class="fw-normal">{{ ubicacion.paisNombre }}</span>
                    </div>
                    <div class="fw-bold">
                      Departamento:
                      <span class="fw-normal">{{
                        ubicacion.departamentoNombre
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="fw-bold">
                      Ciudad:
                      <span class="fw-normal">{{
                        ubicacion.ciudadNombre
                      }}</span>
                    </div>
                    <div class="fw-bold">
                      Dirección:
                      <span class="fw-normal">{{ ubicacion.direccion }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="fw-bold">
                      Nº áreas:
                      <span class="fw-normal">{{ ubicacion.areasTotal }}</span>
                    </div>
                    <div class="fw-bold">
                      Nº equipos:
                      <span class="fw-normal">{{
                        ubicacion.dispositivosTotal
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="ubicacion.isVisible"
            class="w-100 d-flex border-bottom"
            style="overflow-x: auto"
          >
            <div
              style="max-height: 278px"
              v-for="(area, w) in ubicacion.areas"
              :key="w"
            >
              <div>
                <div
                  class="card p-lg-5 p-sm-2 m-3 shadow-none border"
                  style="width: 377px; max-height: 175px overflow-y: auto"
                >
                  <div class="d-flex justify-content-between border-bottom">
                    <div>
                      <span class="text-gray">Área</span>
                      <h4 class="text-gray">{{ area.nombre }}</h4>
                    </div>
                    <div class="d-flex justify-content-evenly">
                      <router-link
                        :to="{
                          name: 'ordenar-area',
                          params: { id: area._id },
                        }"
                      >
                        <i class="bi bi-arrow-repeat mx-2 text-blue"></i>
                      </router-link>
                      <button
                        type="button"
                        class="btn p-0 mt-2 d-flex align-items-start"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEliminarArea"
                      >
                        <i class="bi bi-trash-fill mx-2 text-blue"></i>
                      </button>
                      <div
                        class="modal fade"
                        id="modalEliminarArea"
                        tabindex="-1"
                        aria-labelledby="modalEliminarAreaLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-dialog-centered"
                          style="
                            border-radius: 38px;
                            max-height: 321px;
                            max-width: 375px;
                          "
                        >
                          <div class="modal-content p-5">
                            <div class="text-center my-5">
                              <h2 class="text-blue">¿Eliminar?</h2>
                            </div>
                            <div class="text-gray text-center">
                              <p>
                                ¿¡Está seguro de querer eliminar esta area!?
                              </p>
                            </div>
                            <div class="text-center text-gray my-5">
                              <p>
                                Debe tener en cuenta que al eliminar el área
                                todos los equipos que se encuentran en esta
                                serán eliminados, si desea conservarlos debe
                                cambiarlos de área.
                              </p>
                            </div>
                            <div class="text-center my-5">
                              <button
                                @click="borrarAreas(area._id)"
                                class="btn bg-blue rounded-pill text-white"
                                aria-label="Close"
                                data-bs-dismiss="modal"
                              >
                                Confirmar
                              </button>
                            </div>
                            <div class="text-center">
                              <button
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                class="btn text-blue rounded-pill"
                              >
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <router-link
                        :to="{
                          name: 'editar-area',
                          params: { id: area._id },
                        }"
                      >
                        <i class="bi bi-pencil-fill mx-2 text-blue"></i>
                      </router-link>
                    </div>
                  </div>
                  <div v-if="mostrarEquipos == false">
                    <div class="d-block w-100">
                      <div
                        class="w-100 d-flex justify-content-between py-4 border-bottom"
                        v-for="(equipo, y) in listadoEquipos"
                        :key="y"
                      >
                        <div>
                          <div class="d-flex align-items-center">
                            <img
                              style="border: #787878; width: 20px"
                              src="media/logos/logo_sens_gray.svg"
                              alt=""
                              class="mx-1"
                            />
                            <p style="color: #787878" class="mx-1">
                              {{ equipo.nombre }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-evenly">
                          <i class="bi bi-arrow-repeat mx-2 text-blue"></i>
                          <button
                            class="btn m-0 p-0"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalEliminarEquipo"
                          >
                            <i class="bi bi-trash-fill mx-2 text-blue"></i>
                          </button>
                          <div
                            class="modal fade"
                            id="modalEliminarEquipo"
                            tabindex="-1"
                            aria-labelledby="modalEliminarEquipoLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered"
                              style="
                                border-radius: 38px;
                                max-height: 321px;
                                max-width: 375px;
                              "
                            >
                              <div class="modal-content p-5">
                                <div class="text-center my-5">
                                  <h2 class="text-blue">¿Eliminar?</h2>
                                </div>
                                <div class="text-gray text-center">
                                  <p>
                                    ¿¡Está seguro de querer eliminar este
                                    equipo!?
                                  </p>
                                </div>
                                <div class="text-center text-gray my-5">
                                  <p>
                                    Debe tener en cuenta que esta es una acción
                                    irreversible y una vez eliminado no podrá
                                    volver a consultar información sobre este
                                    equipo.
                                  </p>
                                </div>
                                <div class="text-center my-5">
                                  <button
                                    @click="borrarEquipo(equipo._id)"
                                    class="btn bg-blue rounded-pill text-white"
                                    aria-label="Close"
                                    data-bs-dismiss="modal"
                                  >
                                    Confirmar
                                  </button>
                                </div>
                                <div class="text-center">
                                  <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    class="btn text-blue rounded-pill"
                                  >
                                    Cancelar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <router-link
                            :to="{
                              name: 'profile-equipos-editar',
                              params: { id: equipo._id },
                            }"
                          >
                            <i class="bi bi-pencil-fill mx-2 text-blue"></i>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn m-0 p-0"
                      type="button"
                      @click="traerEquiposPorAreas(area._id)"
                    >
                      <i
                        v-if="mostrarEquipos == false"
                        class="bi bi-chevron-double-up fw-bolder text-blue"
                      ></i>
                      <i
                        v-if="mostrarEquipos == true"
                        class="bi bi-chevron-double-down fw-bolder text-blue"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ubicacion.areas.length === 0" class="text-danger">
              Esta sede no tiene areas
            </div>
          </div>
          <div class="d-flex justify-content-end w-100">
            <div>
              <div class="border-0 p-0">
                <h2 class="m-0" id="ubicacion.nombre">
                  <button
                    class="btn border-0 w-100 justify-content-end text-end p-1"
                    type="button"
                    @click="traerAreasPorSedes(i)"
                  >
                    <i
                      v-if="ubicacion.isVisible"
                      class="bi bi-chevron-double-up fw-bolder text-blue"
                    ></i>
                    <i
                      v-else
                      class="bi bi-chevron-double-down fw-bolder text-blue"
                    ></i>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import sedes from "@/services/sedes.service";
import equipos from "@/services/equipos.service";

export default defineComponent({
  name: "adminLocations",
  setup() {
    const ubicaciones = ref([]);
    const departamentos = ref([]);
    const colombia = "61a7b63262c066e408abe8c9";
    const departamentoId = ref("2901");
    const ciudades = ref([]);
    const ciudadId = ref("20603");
    const traerDepartamentos = () => {
      sedes.listarDepartamentos(colombia).then((res) => {
        console.log(res.data);
        departamentos.value = res.data;
      });
    };
    const traerCiudades = () => {
      sedes.listarCiudades(departamentoId.value).then((res) => {
        console.log(res);
        ciudades.value = res.data;
      });
    };

    const traerSedes = () => {
      sedes.listarSedesPorCiudades(ciudadId.value).then((response) => {
        console.log("respuesta de sedes", response.data);
        response.data.map((a) => {
          a.isVisible = false;
          a.areas = [];
        });
        ubicaciones.value = response.data;
      });
    };
    const borrarUbicacion = (id) => {
      sedes.eliminarSedes(id).then((res) => {
        console.log(res);
        traerSedes();
      });
    };
    const traerAreasPorSedes = (posicion) => {
      const ubicacion = ubicaciones.value[posicion];
      ubicacion.isVisible = !ubicacion.isVisible;

      if (!ubicacion.isVisible) return;

      sedes.listarAreas(ubicacion._id).then((response) => {
        ubicacion.areas = response.data.areas;
        console.log("areas", response);
      });
    };
    const listadoEquipos = ref([]);
    const mostrarEquipos = ref(true);
    const traerEquiposPorAreas = (id) => {
      mostrarEquipos.value = !mostrarEquipos.value;
      console.log(mostrarEquipos.value);

      equipos.traerDispositivosPorAreas(id).then((res) => {
        console.log("respuesta, traer dispositivos por areas", res);
        listadoEquipos.value = res.data;
      });
    };
    const borrarAreas = (id) => {
      sedes.eliminarAreas(id).then((res) => {
        console.log(res.data);
        traerSedes();
      });
    };
    const borrarEquipo = (id) => {
      console.log(id);
    };

    watch(
      () => departamentoId.value,
      () => {
        traerCiudades();
      }
    );
    watch(
      () => ciudadId.value,
      () => {
        traerSedes();
      }
    );
    onMounted(() => {
      setCurrentPageBreadcrumbs("Administrar sedes", ["Pages", "Profile"]);
      traerSedes();
      traerDepartamentos();
    });
    return {
      ubicaciones,
      traerAreasPorSedes,
      borrarUbicacion,
      departamentos,
      departamentoId,
      ciudades,
      ciudadId,
      borrarAreas,
      listadoEquipos,
      mostrarEquipos,
      traerEquiposPorAreas,
      borrarEquipo,
    };
  },
});
</script>
