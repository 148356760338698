import axios from "axios";
import config from "../config/ubicacion.config";

const listarSedes = () => axios.get(`${config.host}/sedes`);
const listarPaises = () => axios.get(`${config.host}/paises`);
const listarDepartamentos = (id) =>
  axios.get(`${config.host}/paises/${id}/departamentos`);
axios.get(`${config.host}/paises/${"61a7b63262c066e408abe8c9"}/departamentos`);
const listarCiudades = (id) =>
  axios.get(`${config.host}/departamentos/${id}/ciudades`);
const listarSedesPorCiudades = (id) =>
  axios.get(`${config.host}/sedes/ciudad/${id}`);
const listarAreas = (id) => axios.get(`${config.host}/sedes/${id}/areas`);
const crearAreas = (id, payload) =>
  axios.post(`${config.host}/sedes/${id}/areas`, payload);
const editarAreas = (id, payload) =>
  axios.put(`${config.host}/sedes/areas/${id}`, payload);
const crearSedes = (payload) => axios.post(`${config.host}/sedes`, payload);
const verSedes = (id) => axios.get(`${config.host}/sedes/ciudad/${id}`);
const eliminarSedes = (id) => axios.delete(`${config.host}/sedes/${id}`);
const eliminarAreas = (id, payload) =>
  axios.delete(`${config.host}/sedes/areas/${id}`, payload);
const traerAreaPorId = (id) => axios.get(`${config.host}/sedes/areas/${id}`);
const verSedePorId = (id) => axios.get(`${config.host}/sedes/${id}`);
const editarSede = (id, payload) =>
  axios.put(`${config.host}/sedes/${id}`, payload);
export default {
  listarSedes,
  listarPaises,
  listarDepartamentos,
  listarCiudades,
  listarSedesPorCiudades,
  listarAreas,
  crearAreas,
  crearSedes,
  editarAreas,
  verSedes,
  eliminarSedes,
  eliminarAreas,
  traerAreaPorId,
  verSedePorId,
  editarSede,
};
